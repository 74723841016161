/*#region fonts*/
.Poppins,
.BodyFont {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.Fredoka {
  font-family: "Fredoka", sans-serif;
}
.LuckiestGuy {
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
}
html {
  font-size: 16px;
}
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #202124;
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/main-bg.jpg");
  background-position: center top;
  background-size: 100%;
  background-repeat: repeat-y;
}
.main-content__container {
  background-color: #FFF;
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/main-content-bg.png");
  background-size: cover;
  padding: 60px 20px;
}
.pageContent.textContent {
  font-size: 1.125rem;
  color: #5C5C5C;
  font-family: "Fredoka", sans-serif;
}
@media (max-width: 450px) {
  .pageContent.textContent a {
    word-break: break-word;
  }
}
@media (max-width: 767px) {
  .pageContent.textContent {
    text-align: center;
  }
}
.pageContent.textContent h2 {
  color: #2D2D2D;
  font-weight: 500;
  font-size: 1.563rem;
}
@media (max-width: 450px) {
  .pageContent.textContent h2 {
    font-size: 1.094rem;
  }
}
.pageContent.textContent h1 {
  margin-top: 0;
  font-family: "Luckiest Guy", cursive;
  color: #E01F26;
  text-transform: uppercase;
  font-size: 2.5rem;
}
@media (max-width: 450px) {
  .pageContent.textContent h1 {
    font-size: 1.75rem;
  }
}
.pageContent.textContent p {
  margin-bottom: 20px;
}
#BodyContent.pageContent.textContent {
  padding-top: 0;
}
#BodyContent.pageContent.textContent a {
  position: relative;
  padding: 0 3px;
  margin: 0 1px;
  display: inline-block;
  color: #EC008C;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  z-index: 1;
}
#BodyContent.pageContent.textContent a:hover,
#BodyContent.pageContent.textContent a:focus {
  color: #fff;
}
#BodyContent.pageContent.textContent a:hover:before,
#BodyContent.pageContent.textContent a:focus:before {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
#BodyContent.pageContent.textContent a:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #EC008C;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: -1;
  -webkit-transform-origin: center bottom;
          transform-origin: center bottom;
  -webkit-transform: scaleY(0.1);
          transform: scaleY(0.1);
}
/*#endregion fonts*/
form {
  position: relative;
  max-width: 100vw;
}
.outer-wrapper {
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}
.outer-wrapper--alt .main-content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.outer-wrapper--product .main-content {
  padding-bottom: 30px;
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #EC008C;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #EC008C;
  font-size: 2rem;
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
}
@media (max-width: 450px) {
  .DetailsTitle h1,
  .DetailsTitle h2,
  .DetailsMainImage {
    font-size: 1.4rem;
  }
}
.DetailsTitle h1,
.DetailsTitle h2 {
  padding: 10px;
}
.product [itemprop="description"] {
  font-size: 16px;
}
a.BackToCategory {
  text-shadow: none;
  border-radius: 4px;
  background-color: #fa0307;
}
@media (max-width: 767px) {
  a.BackToCategory {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    margin-bottom: 0;
  }
}
a.BackToCategory:hover {
  text-shadow: none;
  background-color: #fa0307;
}
.DetailsLeft {
  background-image: none;
  padding-bottom: 0;
}
.BookButtons {
  text-align: center;
}
@media (min-width: 768px) {
  .BookButtons .BookButton,
  .BookButtons .CheckButton {
    width: auto;
    margin-left: auto;
    float: none;
    margin: 0 10px 10px;
  }
}
.toplinks {
  position: relative;
  padding: 0 15px;
  border: 1px solid #FFF;
}
.toplinks__container {
  padding: 10px;
  background: #EC008C;
  position: relative;
}
.toplinks__container:before,
.toplinks__container:after {
  content: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/ribbon-pink.png");
  position: absolute;
  bottom: -40px;
}
.toplinks__container:before {
  left: -55px;
}
.toplinks__container:after {
  right: -55px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.toplinks__ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #FFF;
  font-size: 1.125rem;
  font-family: "Fredoka", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 450px) {
  .toplinks__link {
    font-size: 0.787rem;
  }
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  color: #FEF200;
}
.toplinks__dropdown {
  padding: 10px;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: #EC008C !important;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 5px 10px !important;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  color: #FFF !important;
  font-size: 1.125rem;
  font-family: "Fredoka", sans-serif;
  font-weight: 700 !important;
  text-transform: uppercase;
}
@media (max-width: 450px) {
  .toplinks__dropdown-link {
    font-size: 0.787rem;
  }
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: inherit !important;
  color: #FEF200 !important;
}
.header {
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
  }
}
@media (min-width: 768px) {
  .header {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.header__container {
  background: #FFF;
}
.header__contact-col {
  background: #0685B8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  text-align: center;
  color: #FFF;
  padding: 30px 20px;
}
@media (min-width: 992px) {
  .header__contact-col {
    width: 30%;
  }
}
.header__contact-col h3 {
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  font-size: 2.5rem;
  text-shadow: 0 3px 3px rgba(0, 0, 0, 0.55);
  margin: 0 0 20px 0;
}
@media (max-width: 450px) {
  .header__contact-col h3 {
    font-size: 1.75rem;
  }
}
.header__logo-col-1,
.header__logo-col-2 {
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin: 10px 0;
}
@media (min-width: 1200px) {
  .header__logo-col-1,
  .header__logo-col-2 {
    width: auto;
  }
}
.header__logo-col-1 {
  max-width: 280px;
}
.header__logo-col-2 {
  max-width: 333px;
}
.header__logo-1 {
  display: inline-block;
  position: relative;
  max-width: 280px;
  width: 100%;
}
.header__logo-1:before {
  content: "";
  display: block;
  padding-top: 70.9677%;
}
.header__logo-1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header__logo-2 {
  display: inline-block;
  position: relative;
  max-width: 333px;
  width: 100%;
}
.header__logo-2:before {
  content: "";
  display: block;
  padding-top: 66.3664%;
}
.header__logo-2 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header__phone {
  color: #FFF;
  font-family: "Fredoka", sans-serif;
  font-size: 3.375rem;
  font-weight: 700;
}
@media (max-width: 450px) {
  .header__phone {
    font-size: 2.362rem;
  }
}
.header__phone:hover,
.header__phone:focus {
  color: #fff;
}
.header__email {
  color: #FFF;
  font-family: "Fredoka", sans-serif;
  font-size: 1.625rem;
  word-break: break-all;
}
@media (max-width: 450px) {
  .header__email {
    font-size: 1.137rem;
  }
}
.header__email:hover,
.header__email:focus {
  color: #fff;
}
.book-online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.book-online__widgets {
  text-align: center;
  background: #989100;
  border-radius: 20px;
  padding: 10px 20px;
  position: relative;
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.book-online__widgets h3 {
  color: #FFF;
  font-size: 2.813rem;
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
}
@media (max-width: 450px) {
  .book-online__widgets h3 {
    font-size: 1.969rem;
  }
}
.book-online__widgets #PostcodeSearchLeft select,
.book-online__widgets #PostcodeSearchLeft input,
.book-online__widgets .SearchDate,
.book-online__widgets .SearchCategory,
.book-online__widgets .SearchButton {
  height: 50px;
  border-radius: 10px;
  background-repeat: no-repeat;
  color: #555;
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 10px auto 20px;
  padding-left: 15px;
  border: none;
  font-size: 1rem;
}
.book-online__widgets #PostcodeSearchLeft select::-webkit-input-placeholder,
.book-online__widgets #PostcodeSearchLeft input::-webkit-input-placeholder,
.book-online__widgets .SearchDate::-webkit-input-placeholder,
.book-online__widgets .SearchCategory::-webkit-input-placeholder,
.book-online__widgets .SearchButton::-webkit-input-placeholder {
  color: #555;
  opacity: 1;
}
.book-online__widgets #PostcodeSearchLeft select::-moz-placeholder,
.book-online__widgets #PostcodeSearchLeft input::-moz-placeholder,
.book-online__widgets .SearchDate::-moz-placeholder,
.book-online__widgets .SearchCategory::-moz-placeholder,
.book-online__widgets .SearchButton::-moz-placeholder {
  color: #555;
  opacity: 1;
}
.book-online__widgets #PostcodeSearchLeft select::-ms-input-placeholder,
.book-online__widgets #PostcodeSearchLeft input::-ms-input-placeholder,
.book-online__widgets .SearchDate::-ms-input-placeholder,
.book-online__widgets .SearchCategory::-ms-input-placeholder,
.book-online__widgets .SearchButton::-ms-input-placeholder {
  color: #555;
  opacity: 1;
}
.book-online__widgets #PostcodeSearchLeft select::placeholder,
.book-online__widgets #PostcodeSearchLeft input::placeholder,
.book-online__widgets .SearchDate::placeholder,
.book-online__widgets .SearchCategory::placeholder,
.book-online__widgets .SearchButton::placeholder {
  color: #555;
  opacity: 1;
}
.book-online__widgets #PostcodeSearchLeft select,
.book-online__widgets #PostcodeSearchLeft input {
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/icon-area.png");
  background-size: 20px 25px;
  background-position: right 26px center;
}
.book-online__widgets .SearchDate {
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/icon-date.png");
  background-size: 25px 25px;
  background-position: right 22px center;
}
.book-online__widgets .SearchCategory {
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/icon-products.png");
  background-size: 23px 26px;
  background-position: right 24px center;
}
.book-online__widgets .SearchButton {
  padding-left: 0;
  font-size: 1.375rem;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #E01F26;
  background: #FEF200;
  border-radius: 10px;
  text-transform: uppercase;
  font-weight: 700;
}
.banner #CarouselContainer {
  padding-top: 68.78%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__title-container {
  background-color: #FEF200;
  text-align: center;
  font-family: "Luckiest Guy", cursive;
  font-size: 2.5rem;
  color: #E01F26;
  text-transform: uppercase;
  padding: 10px 20px;
  position: relative;
}
@media (max-width: 450px) {
  .banner__title-container {
    font-size: 1.75rem;
  }
}
.banner__title-container:before,
.banner__title-container:after {
  content: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/ribbon-yellow.png");
  position: absolute;
  bottom: -40px;
}
.banner__title-container:before {
  left: -56px;
}
.banner__title-container:after {
  right: -56px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.banner #CarouselContainer {
  border-radius: 20px;
}
.banner__container {
  background: #FFF;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .banner__inner {
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: -webkit-box;
    display: flex;
    -moz-justify-content: center;
    -ms-justify-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -moz-align-items: center;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.banner__right,
.banner__left {
  padding: 10px 20px;
}
.banner__right .search {
  background: #0685B8;
  border-radius: 20px;
  padding: 20px;
  margin-top: 30px;
}
.banner__right .search .SearchPanel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.banner__right .search .SearchPanel .SearchBoxTextBox {
  height: 50px;
  border-radius: 10px;
  background-image: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/icon-search.png");
  background-size: 30px;
  background-position: right 24px center;
  background-repeat: no-repeat;
  border: none;
  padding-left: 5%;
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}
@media (max-width: 450px) {
  .banner__right .search .SearchPanel .SearchBoxTextBox {
    font-size: 0.7rem;
  }
}
.banner__right .search .SearchPanel .SearchProductsButton {
  background: #E01F26;
  color: #FFF;
  font-weight: 700;
  font-size: 1.375rem;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  border-radius: 10px;
}
@media (max-width: 450px) {
  .banner__right .search .SearchPanel .SearchProductsButton {
    font-size: 0.962rem;
  }
}
.featured-rotator .DetailsThumbnail.thumbnail {
  border: none;
  border-radius: 10px;
  padding: 0 1% 83%;
  margin: 10px 0;
}
@media (min-width: 768px) {
  .featured-rotator .DetailsThumbnail.thumbnail {
    margin: 18% 0;
  }
}
@media (min-width: 1200px) {
  .featured-rotator .DetailsThumbnail.thumbnail {
    margin: 10px 0;
  }
}
.featured-rotator .DetailsThumbnail.thumbnail:before {
  content: "";
  display: block;
  padding-top: 96.6004%;
  width: 100%;
  float: left;
}
.featured-rotator .DetailsThumbnail.thumbnail img {
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.featured-rotator__wrapper .slick-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}
.featured-rotator .slick-slide {
  margin: 0 5px;
}
.featured-rotator .slick-track {
  min-height: 152px;
}
.featured-rotator .slick-list {
  background: #0685B8;
  position: relative;
  display: block;
  overflow: hidden;
  margin: auto 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 768px) {
  .featured-rotator .slick-list {
    width: 94%;
  }
}
.featured-rotator__button {
  display: none !important;
  background: none;
  border: none;
  padding: 0;
  top: 0;
  margin-bottom: auto;
  position: relative;
  height: 100%;
}
@media (min-width: 768px) {
  .featured-rotator__button {
    display: inline-block !important;
  }
}
.featured-rotator__button--next {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.featured-rotator__container {
  background: #FFF;
  padding: 10px 0;
}
.category-links__link {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding: 15px;
  text-align: center;
  word-wrap: break-word;
  color: #fff;
  font-size: 1.25rem;
  font-family: "Fredoka", sans-serif;
  font-weight: 700;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.45);
  text-transform: uppercase;
  border-radius: 10px;
}
@media (max-width: 450px) {
  .category-links__link {
    font-size: 0.875rem;
  }
}
.category-links__link:hover,
.category-links__link.active,
.category-links__link:focus {
  color: #fff;
  text-shadow: 0 0 0 rgba(0, 0, 0, 0.45);
}
.category-links__link:nth-child(4n+1) {
  background: #FCB21D;
}
.category-links__link:nth-child(4n+2) {
  background: #139DD4;
}
.category-links__link:nth-child(4n+3) {
  background: #EC008C;
}
.category-links__link:nth-child(4n+4) {
  background: #10648F;
}
.castlePanel {
  width: 100%;
  margin: 0 0 30px;
  display: block;
  border: none;
  overflow: hidden;
  padding: 10px 10px 20px;
  border-radius: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.castlePanel:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.castlePanel:hover .SingleImagePanel,
.castlePanel:hover .castlePriceDefault,
.castlePanel:hover .castlePriceDiscounted,
.castlePanel:hover .castleCheckBook {
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.castlePanel:hover .castleTitlePanel {
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
  border-radius: 20px;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 94.1667%;
  width: 100%;
}
.castlePanel .SingleImagePanel img {
  top: 0;
  left: 0;
  width: 100%;
}
.castlePanel .castleTitlePanel {
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.castlePanel .castleTitlePanel a {
  font-size: 1.625rem;
  font-family: "Luckiest Guy", cursive;
  color: #10648F;
  text-transform: uppercase;
}
.castlePanel .shortProductDescription {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #595959;
  font-weight: 500;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0 5px;
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  width: 30%;
  display: inline-block;
  background: #FCB21D;
  border-radius: 10px;
  color: #093756;
  font-family: "Fredoka", sans-serif;
  font-weight: 700;
  padding: 10px 20px;
  margin-right: 3%;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.8rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 1rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4;
  padding: 0 5px;
  margin-top: 10px;
  text-transform: uppercase;
  font-size: 1.125rem;
  font-family: "Fredoka", sans-serif;
  font-weight: 700;
  padding: 10px 20px;
  background: #EC008C;
  border-radius: 10px;
  color: #FFF;
  width: 60%;
  display: inline-block;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
@media (max-width: 420px) {
  .page-listings > div,
  .PageListings > div {
    width: 100%;
  }
}
.page-listings__container,
.PageListings__container {
  background: #FFF;
  padding: 60px 20px;
  text-align: center;
}
.page-listings__container h2,
.PageListings__container h2 {
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  color: #E01F26;
  font-size: 2.5rem;
  margin: 30px 0;
}
@media (max-width: 450px) {
  .page-listings__container h2,
  .PageListings__container h2 {
    font-size: 1.75rem;
  }
}
.gallery {
  text-align: center;
}
.gallery__container {
  background: #139DD4;
  padding: 60px 20px;
}
.gallery h2 {
  color: #FFF;
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin: 0;
}
.gallery__inner {
  position: relative;
  z-index: 2;
}
@media (min-width: 992px) {
  .gallery__inner {
    margin: 0 60px;
  }
}
.gallery__slide {
  width: 100%;
  z-index: 1;
  position: relative;
}
@media (min-width: 768px) {
  .gallery__slide {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .gallery__slide {
    width: 32%;
  }
}
.gallery__slide .DetailsThumbnail.thumbnail {
  border-radius: 25px;
  border: 7px solid #FFF;
  padding: 0% 1% 73%;
}
.gallery__slide .DetailsThumbnail.thumbnail img {
  top: 0 !important;
  left: 0 !important;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.gallery__slide .DetailsThumbnail.thumbnail img:before {
  padding-top: 76.2742%;
}
.gallery__button {
  display: none !important;
  position: absolute;
  bottom: 0;
  background-color: transparent;
  border: none;
  z-index: 2;
}
@media (min-width: 992px) {
  .gallery__button {
    display: block !important;
  }
}
.gallery__button--next {
  right: 0;
  top: 0;
  -webkit-transform: scaleX(-1) translatex(-60px);
          transform: scaleX(-1) translatex(-60px);
  display: none;
}
@media (min-width: 992px) {
  .gallery__button--next {
    display: block;
  }
}
.gallery__button--prev {
  display: none;
  top: 0;
  -webkit-transform: translatex(-60px);
          transform: translatex(-60px);
}
@media (min-width: 992px) {
  .gallery__button--prev {
    display: block;
  }
}
.gallery .slick-list {
  padding: 60px 0 !important;
  position: relative;
}
.gallery .slick-center {
  z-index: 3;
}
@media (min-width: 992px) {
  .gallery .slick-center {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
}
.gallery .slick-center img:before {
  padding-top: 76.2742%;
}
.gallery .slick-slide {
  padding: 10px;
}
.testimonials__container {
  background: #FFF;
  margin-bottom: 60px;
}
.testimonials .slick-slide:nth-child(3n+1) .RotatorTestimonial {
  background: #FCB21D;
}
.testimonials .slick-slide:nth-child(3n+2) .RotatorTestimonial {
  background: #EC008C;
}
.testimonials .slick-slide:nth-child(3n+3) .RotatorTestimonial {
  background: #139DD4;
}
.testimonials .slick-slide .RotatorTestimonial {
  margin: 10px;
  border-radius: 25px;
  padding: 30px;
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  text-align: center;
}
.testimonials .RotatorTestimonialContent:after {
  content: url("https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/icon-speech.png");
  margin: 10px 0;
}
.testimonials__inner {
  position: relative;
  padding: 60px 0;
}
.testimonials__inner h2 {
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  text-align: center;
  color: #E01F26;
  font-size: 2.5rem;
  margin: 10px 0 40px;
}
.testimonials time {
  display: none;
}
.testimonials a[itemprop="author"] {
  color: #FFF;
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  font-size: 1.563rem;
  margin: 10px 0;
}
.testimonials p[itemprop="reviewBody"] {
  color: #FFF;
  font-size: 1rem;
  font-family: "Fredoka", sans-serif;
  margin: 10px 0;
}
.footer {
  color: #fff;
}
.footer a,
.footer p {
  color: #fff;
  font-family: "Fredoka", sans-serif;
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.footer a:hover,
.footer p:hover,
.footer a:focus,
.footer p:focus {
  color: #FCB21D;
}
.footer h3 {
  color: #FCB21D;
  font-family: "Luckiest Guy", cursive;
  text-transform: uppercase;
  font-size: 1.75rem;
  text-align: center;
}
@media (max-width: 450px) {
  .footer h3 {
    font-size: 1.225rem;
  }
}
@media (min-width: 768px) {
  .footer h3 {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footer__text-left-md {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .footer__text-right-md {
    text-align: right;
  }
}
.footer__top {
  padding: 60px 0;
  background: #202124;
}
@media (min-width: 768px) {
  .footer__top-col {
    margin: 0 20px;
  }
}
.footer__top-col-logos {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .footer__top-col-logos {
    display: block;
    width: 50%;
  }
}
@media (min-width: 992px) {
  .footer__top-col-logos {
    width: 30%;
  }
}
.footer__top-col-newsletter {
  width: 100%;
}
@media (min-width: 768px) {
  .footer__top-col-newsletter {
    width: 40%;
  }
}
@media (min-width: 992px) {
  .footer__top-col-newsletter {
    width: 25%;
  }
}
.footer__top-logos {
  position: relative;
  text-align: center;
}
.footer__logo {
  width: 100%;
  display: block;
  margin: 20px 0;
}
@media (min-width: 768px) {
  .footer__logo {
    width: 48%;
    display: inline-block;
  }
}
.footer__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 768px) {
  .footer__social-links {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.footer__social-link {
  margin: 0 5px;
}
.footer__top-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (min-width: 768px) {
  .footer__top-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .footer__top-inner {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.footer__links {
  text-align: center;
}
@media (min-width: 768px) {
  .footer__links {
    text-align: left;
  }
}
.footer__link {
  margin: 5px 0;
  display: block;
}
.footer #NewsletterControl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.footer #NewsletterControl input {
  height: 50px;
  background: transparent;
  color: #FFF;
  border: 1px solid #FFF;
  border-radius: 5px;
  padding-left: 10px;
  margin: 5px 0;
}
.footer #NewsletterControl input::-webkit-input-placeholder {
  color: #FFF;
}
.footer #NewsletterControl input::-moz-placeholder {
  color: #FFF;
}
.footer #NewsletterControl input::-ms-input-placeholder {
  color: #FFF;
}
.footer #NewsletterControl input::placeholder {
  color: #FFF;
}
.footer #NewsletterControl .NewsletterSubmit {
  font-family: "Fredoka", sans-serif;
  color: #FFF;
  text-transform: uppercase;
  background: #139DD4;
  border: 1px solid #139DD4;
  border-radius: 10px;
  font-weight: 600;
  font-size: 1rem;
  padding: 15px 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 5px 0;
}
.footer__bottom {
  padding: 30px 0;
  text-align: center;
  background: #FFF;
  color: #202124;
}
.footer #BCNLink {
  display: inline-block;
  margin-top: 15px;
  color: #EC008C;
}
@media (min-width: 992px) {
  .footer #BCNLink {
    margin-top: 0;
  }
}
