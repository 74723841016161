@import "../Autobopr/Assets/functions.less";
@xxs: ~"(max-width: 450px)";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@desktop: ~"(min-width: 1330px)";
@mobileNavColor: #EC008C;
@castleDetailsColor: #EC008C;
@blue: #139DD4;
@pink: #EC008C;
@yellow: #FCB21D;
@CloudUrl: "https://bouncycastlenetwork-res.cloudinary.com/image/upload/f_auto/Sites/5-star-gaming/";
/*#region fonts*/
.Poppins, .BodyFont {
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}
.Fredoka {
	font-family: "Fredoka", sans-serif;
}
.LuckiestGuy {
	font-family: "Luckiest Guy", cursive;
	text-transform: uppercase;
}
// converts from px to rem & scales down for xxs screens
.fontSize(@fontSizePx,@scaleXxs: true,@xxsScale: 0.7, @rootFontSizePx: 16,@xxs: ~"(max-width: 450px)" ) {
	font-size: unit(round(@fontSizePx/@rootFontSizePx,3),rem);
	& when(@scaleXxs = true) {
		@media @xxs {
			font-size: unit(round((@fontSizePx * @xxsScale)/@rootFontSizePx,3),rem);
		}
	}
}
html {
	font-size: 16px;
}
body {
	.BodyFont;
	background-color: #202124;
	background-image: url("@{CloudUrl}main-bg.jpg");
	background-position: center top;
	background-size: 100%;
	background-repeat: repeat-y;
}
.main-content {
	&__container {
		background-color: #FFF;
		background-image: url("@{CloudUrl}main-content-bg.png");
		background-size: cover;
		padding: 60px 20px;
	}
}
.pageContent.textContent {
	.fontSize(18,false);
	color: #5C5C5C;
	.Fredoka;
	@media @xxs {
		a {
			// for long links in chrome
			word-break: break-word;
		}
	}
	@media @mobile {
		text-align: center;
	}
	h2 {
		color: #2D2D2D;
		font-weight: 500;
		.fontSize(25);
	}
	h1 {
		margin-top: 0;
		.LuckiestGuy;
		color: #E01F26;
		text-transform: uppercase;
		.fontSize(40);
	}
	p {
		margin-bottom: 20px;
	}
}
#BodyContent.pageContent.textContent {
	padding-top: 0;
	@linkColor: #EC008C;
	a {
		position: relative;
		padding: 0 3px;
		margin: 0 1px;
		display: inline-block;
		color: @linkColor;
		transition: color 0.3s;
		z-index: 1;
		&:hover, &:focus {
			color: #fff;
			&:before {
				transform: scaleY(1);
			}
		}
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: @linkColor;
			transition: transform 0.3s;
			z-index: -1;
			transform-origin: center bottom;
			transform: scaleY(0.1);
		}
	}
}
/*#endregion fonts*/
form {
	position: relative;
	max-width: 100vw;
}
.outer-wrapper {
	max-width: 1920px;
	margin: 0 auto;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
	&--alt {
		.main-content {
			flex-grow: 1;
		}
	}
	&--product {
		.main-content {
			padding-bottom: 30px;
		}
	}
}
.container {
	position: relative;
	width: 100%;
	@media @desktop {
		width: 1300px;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();
	.navbar {
		margin-bottom: 0;
	}
	@media @tablet {
		display: none !important;
	}
	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}
	.navbar-toggle .icon-bar {
		background: white;
	}
	.row {
		padding-top: 10px;
	}
	.input-group {
		margin-bottom: 0;
	}
}
#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
	.fontSize(32);
	.LuckiestGuy;
}
.DetailsTitle h1, .DetailsTitle h2 {
	padding: 10px;
}
.product [itemprop="description"] {
	font-size: 16px;
}
a.BackToCategory {
	@bgColor: #fa0307;
	text-shadow: none;
	border-radius: 4px;
	background-color: @bgColor;
	@media @mobile {
		transform: translateY(30px);
		margin-bottom: 0;
	}
	&:hover {
		text-shadow: none;
		background-color: @bgColor;
	}
}
.DetailsLeft {
	background-image: none;
	padding-bottom: 0;
}
.BookButtons {
	text-align: center;
	.BookButton, .CheckButton {
		@media @sm {
			width: auto;
			margin-left: auto;
			float: none;
			margin: 0 10px 10px;
		}
	}
}
.toplinks {
	position: relative;
	padding: 0 15px;
	border: 1px solid #FFF;
	&__wrapper {
	}
	&__container {
		padding: 10px;
		background: @pink;
		position: relative;
		&:before, &:after {
			content: url("@{CloudUrl}ribbon-pink.png");
			position: absolute;
			bottom: -40px;
		}
		&:before {
			left: -55px;
		}
		&:after {
			right: -55px;
			transform: scaleX(-1);
		}
	}
	&__ul {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
		align-items: center;
	}
	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}
	&__link {
		position: relative;
		display: block;
		padding: 15px 5px;
		transition: color 0.3s;
		color: #FFF;
		.fontSize(18);
		.Fredoka;
		font-weight: 700;
		text-transform: uppercase;
		&:hover, &.active, &:focus {
			color: #FEF200;
		}
	}
	&__dropdown {
		padding: 10px;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: @pink !important;
		box-shadow: 0 0 3px rgba(0,0,0,0.25);
	}
	&__dropdown-li {
		display: block;
	}
	&__dropdown-link {
		position: relative;
		padding: 5px 10px !important;
		transition: color 0.3s;
		color: #FFF !important;
		.fontSize(18);
		.Fredoka;
		font-weight: 700 !important;
		text-transform: uppercase;
		&:hover, &:focus, &.active {
			background-color: inherit !important;
			color: #FEF200 !important;
		}
	}
}
.header {
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	@media @mobile {
		padding-top: 80px;
	}
	@media @sm {
		flex-direction: row;
	}
	&__container {
		background: #FFF;
	}
	&__contact-col {
		background: #0685B8;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 10px;
		text-align: center;
		color: #FFF;
		padding: 30px 20px;
		@media @md {
			width: 30%;
		}
		h3 {
			.LuckiestGuy;
			.fontSize(40);
			text-shadow: 0 3px 3px rgba(0,0,0,0.55);
			margin: 0 0 20px 0;
		}
	}
	&__logo-col-1, &__logo-col-2 {
		width: 100%;
		flex-grow: 1;
		margin: 10px 0;
		@media @lg {
			width: auto;
		}
	}
	&__logo-col-1 {
		max-width: 280px;
	}
	&__logo-col-2 {
		max-width: 333px;
	}
	&__logo-1 {
		display: inline-block;
		position: relative;
		max-width: 280px;
		width: 100%;
		&:before {
			content: "";
			display: block;
			padding-top: 70.9677%;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__logo-2 {
		display: inline-block;
		position: relative;
		max-width: 333px;
		width: 100%;
		&:before {
			content: "";
			display: block;
			padding-top: 66.3664%;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__phone {
		color: #FFF;
		.Fredoka;
		.fontSize(54);
		font-weight: 700;
		&:hover, &:focus {
			color: #fff;
		}
	}
	&__email {
		color: #FFF;
		.Fredoka;
		.fontSize(26);
		word-break: break-all;
		&:hover, &:focus {
			color: #fff;
		}
	}
}
.book-online {
	display: flex;
	flex-direction: column;
	&__widgets {
		position: relative;
		text-align: center;
		background: #989100;
		border-radius: 20px;
		padding: 10px 20px;
		position: relative;
		display: block;
		height: fit-content;
		h3 {
			color: #FFF;
			.fontSize(45);
			text-transform: uppercase;
			.LuckiestGuy;
		}
		#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory, .SearchButton {
			height: 50px;
			border-radius: 10px;
			background-repeat: no-repeat;
			color: #555;
			display: block;
			width: 100%;
			max-width: 350px;
			margin: 10px auto 20px;
			padding-left: 15px;
			border: none;
			.fontSize(16,false);
			&::placeholder {
				color: #555;
				opacity: 1;
			}
			@media @md {
			}
		}
		#PostcodeSearchLeft select, #PostcodeSearchLeft input {
			background-image: url("@{CloudUrl}icon-area.png");
			background-size: 20px 25px;
			background-position: right 26px center;
		}
		.SearchDate {
			background-image: url("@{CloudUrl}icon-date.png");
			background-size: 25px 25px;
			background-position: right 22px center;
		}
		.SearchCategory {
			background-image: url("@{CloudUrl}icon-products.png");
			background-size: 23px 26px;
			background-position: right 24px center;
		}
		.SearchButton {
			padding-left: 0;
			.fontSize(22,false);
			.FlexCenter;
			color: #E01F26;
			background: #FEF200;
			border-radius: 10px;
			text-transform: uppercase;
			font-weight: 700;
		}
	}
}
.banner {
	.BannerPanelSet(68.7800%);
	&__title {
		&-container {
			background-color: #FEF200;
			text-align: center;
			.LuckiestGuy;
			.fontSize(40);
			color: #E01F26;
			text-transform: uppercase;
			padding: 10px 20px;
			position: relative;
			&:before, &:after {
				content: url("@{CloudUrl}ribbon-yellow.png");
				position: absolute;
				bottom: -40px;
			}
			&:before {
				left: -56px;
			}
			&:after {
				right: -56px;
				transform: scaleX(-1);
			}
		}
	}
	#CarouselContainer {
		border-radius: 20px;
	}
	&__container {
		background: #FFF;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	&__inner {
		@media @md {
			.FlexCenter;
		}
	}
	&__right, &__left {
		padding: 10px 20px;
	}
	&__right {
		.search {
			background: #0685B8;
			border-radius: 20px;
			padding: 20px;
			margin-top: 30px;
			.SearchPanel {
				display: flex;
				flex-direction: column;
				.SearchBoxTextBox {
					height: 50px;
					border-radius: 10px;
					background-image: url("@{CloudUrl}icon-search.png");
					background-size: 30px;
					background-position: right 24px center;
					background-repeat: no-repeat;
					border: none;
					padding-left: 5%;
					.fontSize(16);
					color: #555;
					margin-bottom: 15px;
				}
				.SearchProductsButton {
					background: #E01F26;
					color: #FFF;
					font-weight: 700;
					.fontSize(22);
					text-align: center;
					padding: 10px;
					text-transform: uppercase;
					border-radius: 10px;
				}
			}
		}
	}
}
.featured-rotator {
	.DetailsThumbnail.thumbnail {
		border: none;
		border-radius: 10px;
		padding: 0 1% 83%;
		margin: 10px 0;
		@media @sm {
			margin: 18% 0;
		}
		@media @lg {
			margin: 10px 0;
		}
		&:before {
			content: "";
			display: block;
			padding-top: 96.6004%;
			width: 100%;
			float: left;
		}
		img {
			opacity: 1;
			top: 0;
			left: 0;
			width: 100%;
			object-fit: cover;
		}
	}
	&__wrapper {
		.slick-slider {
			display: flex;
			margin: 0;
		}
	}
	.slick-slide {
		margin: 0 5px;
	}
	.slick-track {
		min-height: 152px;
	}
	.slick-list {
		background: #0685B8;
		position: relative;
		display: block;
		overflow: hidden;
		margin: auto 0;
		padding: 0;
		width: 100%;
		@media @sm {
			width: 94%;
		}
	}
	&__button {
		display: none !important;
		background: none;
		border: none;
		padding: 0;
		top: 0;
		margin-bottom: auto;
		position: relative;
		height: 100%;
		@media @sm {
			display: inline-block !important;
		}
		&--next {
			transform: scaleX(-1);
		}
	}
	&__container {
		background: #FFF;
		padding: 10px 0;
	}
}
.category-links {
	&__link {
		position: relative;
		display: block;
		margin-bottom: 15px;
		padding: 15px;
		text-align: center;
		word-wrap: break-word;
		color: #fff;
		.fontSize(20);
		.Fredoka;
		font-weight: 700;
		transition: all 0.5s;
		text-shadow: 0 4px 4px rgba(0,0,0,0.45);
		text-transform: uppercase;
		border-radius: 10px;
		&:hover, &.active, &:focus {
			color: #fff;
			text-shadow: 0 0 0 rgba(0,0,0,0.45);
		}
		&:nth-child(4n+1) {
			background: @yellow;
		}
		&:nth-child(4n+2) {
			background: @blue;
		}
		&:nth-child(4n+3) {
			background: @pink;
		}
		&:nth-child(4n+4) {
			background: #10648F;
		}
	}
}
.castlePanel {
	width: 100%;
	margin: 0 0 30px;
	display: block;
	border: none;
	overflow: hidden;
	padding: 10px 10px 20px;
	border-radius: 10px;
	box-shadow: none;
	&:hover {
		box-shadow: none;
		.SingleImagePanel, .castlePriceDefault, .castlePriceDiscounted, .castleCheckBook {
			box-shadow: 0 3px 5px rgba(0,0,0,0.3);
		}
		.castleTitlePanel {
			text-shadow: 0 3px 5px rgba(0,0,0,0.3);
		}
	}
	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;
		border-radius: 20px;
		overflow: hidden;
		transition: all 0.5s;
		&:before {
			content: "";
			display: block;
			padding-top: 94.1667%;
			width: 100%;
		}
		img {
			top: 0;
			left: 0;
			width: 100%;
		}
	}
	.castleTitlePanel {
		padding: 0 5px;
		display: flex;
		margin-top: 10px;
		justify-content: center;
		align-items: center;
		transition: all 0.5s;
		a {
			.fontSize(26,false);
			.LuckiestGuy;
			color: #10648F;
			text-transform: uppercase;
		}
	}
	.shortProductDescription {
		.Poppins;
		.fontSize(16,false);
		color: #595959;
		font-weight: 500;
	}
	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0 5px;
		margin-top: 10px;
		.Poppins;
		.fontSize(18,false);
		width: 30%;
		display: inline-block;
		background: @yellow;
		border-radius: 10px;
		color: #093756;
		.Fredoka;
		font-weight: 700;
		padding: 10px 20px;
		margin-right: 3%;
		transition: all 0.5s;
		span.oldprice {
			font-size: 0.8rem;
		}
		span.newprice {
			font-size: 1rem;
		}
	}
	.castleCheckBook {
		height: auto;
		order: 4;
		padding: 0 5px;
		margin-top: 10px;
		text-transform: uppercase;
		.fontSize(18,false);
		.Fredoka;
		font-weight: 700;
		padding: 10px 20px;
		background: @pink;
		border-radius: 10px;
		color: #FFF;
		width: 60%;
		display: inline-block;
		transition: all 0.5s;
	}
}
.page-listings, .PageListings {
	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
	&__container {
		background: #FFF;
		padding: 60px 20px;
		text-align: center;
		h2 {
			.LuckiestGuy;
			color: #E01F26;
			.fontSize(40);
			margin: 30px 0;
		}
	}
}
.gallery {
	text-align: center;
	&__container {
		background: @blue;
		padding: 60px 20px;
	}
	h2 {
		color: #FFF;
		.LuckiestGuy;
		.fontSize(40,false);
		margin: 0;
	}
	&__inner {
		position: relative;
		z-index: 2;
		@media @md {
			margin: 0 60px;
		}
	}
	&__slide {
		width: 100%;
		z-index: 1;
		position: relative;
		@media @sm {
			width: 50%;
		}
		@media @md {
			width: 32%;
		}
		.DetailsThumbnail.thumbnail {
			border-radius: 25px;
			border: 7px solid #FFF;
			padding: 0% 1% 73%;
			img {
				top: 0 !important;
				left: 0 !important;
				object-fit: cover;
				z-index: 1;
				&:before {
					padding-top: 76.2742%;
				}
			}
		}
	}
	&__button {
		display: none !important;
		position: absolute;
		bottom: 0;
		background-color: transparent;
		border: none;
		z-index: 2;
		@media @md {
			display: block !important;
		}
		&--next {
			right: 0;
			top: 0;
			transform: scaleX(-1) translatex(-60px);
			display: none;
			@media @md {
				display: block;
			}
		}
		&--prev {
			display: none;
			top: 0;
			transform: translatex(-60px);
			@media @md {
				display: block;
			}
		}
	}
	.slick {
		&-list {
			padding: 60px 0 !important;
			position: relative;
		}
		&-center {
			z-index: 3;
			@media @md {
				transform: scale(1.3);
			}
			img {
				&:before {
					padding-top: 76.2742%;
				}
			}
		}
		&-slide {
			padding: 10px;
		}
	}
}
.testimonials {
	&__container {
		background: #FFF;
		margin-bottom: 60px;
	}
	.slick-slide {
		&:nth-child(3n+1) {
			.RotatorTestimonial {
				background: @yellow;
			}
		}
		&:nth-child(3n+2) {
			.RotatorTestimonial {
				background: @pink;
			}
		}
		&:nth-child(3n+3) {
			.RotatorTestimonial {
				background: @blue;
			}
		}
		.RotatorTestimonial {
			margin: 10px;
			border-radius: 25px;
			padding: 30px;
		}
	}
	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;
		&:after {
			content: url("@{CloudUrl}icon-speech.png");
			margin: 10px 0;
		}
	}
	&__inner {
		position: relative;
		padding: 60px 0;
		h2 {
			.LuckiestGuy;
			text-align: center;
			color: #E01F26;
			.fontSize(40,false);
			margin: 10px 0 40px;
		}
	}
	time {
		display: none;
	}
	a[itemprop="author"] {
		color: #FFF;
		.LuckiestGuy;
		.fontSize(25,false);
		margin: 10px 0;
	}
	p[itemprop="reviewBody"] {
		color: #FFF;
		.fontSize(16,false);
		.Fredoka;
		margin: 10px 0;
	}
}
.footer {
	color: #fff;
	a, p {
		color: #fff;
		.Fredoka;
		.fontSize(18,false);
		transition: all 0.5s;
		&:hover, &:focus {
			color: @yellow;
		}
	}
	h3 {
		color: @yellow;
		.LuckiestGuy;
		.fontSize(28);
		text-align: center;
		@media @sm {
			text-align: left;
		}
	}
	&__text-left-md {
		@media @md {
			text-align: left;
		}
	}
	&__text-right-md {
		@media @md {
			text-align: right;
		}
	}
	&__top {
		padding: 60px 0;
		background: #202124;
		&-col {
			@media @sm {
				margin: 0 20px;
			}
			&-logos {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				@media @sm {
					display: block;
					width: 50%;
				}
				@media @md {
					width: 30%;
				}
			}
			&-newsletter {
				width: 100%;
				@media @sm {
					width: 40%;
				}
				@media @md {
					width: 25%;
				}
			}
		}
		&-logos {
			position: relative;
			text-align: center;
		}
	}
	&__logo {
		width: 100%;
		display: block;
		margin: 20px 0;
		@media @sm {
			width: 48%;
			display: inline-block;
		}
	}
	&__social-links {
		display: flex;
		margin: 20px 0;
		justify-content: center;
		@media @sm {
			justify-content: flex-start;
		}
	}
	&__social-link {
		margin: 0 5px;
	}
	&__top-inner {
		display: flex;
		flex-direction: column;
		@media @sm {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-evenly;
		}
		@media @md {
			justify-content: space-between;
		}
	}
	&__links {
		text-align: center;
		@media @sm {
			text-align: left;
		}
	}
	&__link {
		margin: 5px 0;
		display: block;
	}
	#NewsletterControl {
		display: flex;
		flex-direction: column;
		input {
			height: 50px;
			background: transparent;
			color: #FFF;
			border: 1px solid #FFF;
			border-radius: 5px;
			padding-left: 10px;
			margin: 5px 0;
			&::placeholder {
				color: #FFF;
			}
		}
		.NewsletterSubmit {
			.Fredoka;
			color: #FFF;
			text-transform: uppercase;
			background: @blue;
			border: 1px solid @blue;
			border-radius: 10px;
			font-weight: 600;
			.fontSize(16,false);
			padding: 15px 30px;
			width: fit-content;
			margin: 5px 0;
		}
	}
	&__bottom {
		padding: 30px 0;
		text-align: center;
		background: #FFF;
		color: #202124;
	}
	#BCNLink {
		display: inline-block;
		margin-top: 15px;
		color: @pink;
		@media @md {
			margin-top: 0;
		}
	}
}
